<template>
  <v-card
    class="pa-0"
    style="margin-top: 20px; margin-left: 20px; margin-right: 20px"
  >
    <v-toolbar min-width="100%" dark color="primary">
      <v-card-title>My Billing</v-card-title>
      <v-spacer></v-spacer>

      <v-dialog v-model="dialogs" persistent max-width="50%">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on"> Add New </v-btn>
        </template>
        <v-card>
          <v-toolbar width="100%" class="mx-0" color="primary " dark>
            <v-card-text class="text-h5"> Admin Billing </v-card-text>
            <v-spacer> </v-spacer>
          </v-toolbar>
          <v-form ref="form">
            <v-container fluid>
              <v-row>
                <v-col>
                  <!-- <v-autocomplete
                    :items="userData"
                    item-text="name"
                    item-value="id"
                    v-model="rooms"
                    label="Select Class"
                    @change="getTotalAmount"
                  /> -->
                  <v-autocomplete
                    v-model="rooms"
                    :items="filteredUserData"
                    label="Select a class"
                    item-text="name"
                    item-value="id"
                    @change="getTotalAmount"
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                    :items="filteredUserData"
                    item-text="total_amount"
                    item-value="id"
                    v-model="rooms"
                    label="Total Amount"
                    @change="getTotalAmount"
                    disabled
                  />
                </v-col>
              </v-row>
            </v-container>

            <v-card-text>
              <v-text-field
                v-model="pay_amount"
                :rules="$requiredRules"
                label="Amount*"
              ></v-text-field>

              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                placeholder="Pick a Receipt_copy"
                v-model="receipt_copy"
                prepend-icon="mdi-camera"
                label="Receipt_copy"
              ></v-file-input>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" @click="save"> Save </v-btn>

              <v-btn color="primary" @click="cancel_dialog"> cancel </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-row
      style="
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        border: 1px solid;
        margin-bottom: 10px;
      "
    >
      <v-col>
        <v-autocomplete
          v-model="selectedClassroom"
          :items="filteredUserData"
          label="Filter by Classroom"
          :search-input.sync="searchClassroom"
          @change="filterByClassroom"
          item-text="name"
          item-value="id"
          style="font-size: large"
        ></v-autocomplete>
      </v-col>
      <v-col>
        <div>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="start_date"
                label="Start Date"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="end_date"
                label="End Date"
                type="date"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <DataTableSSR
      apiEndPoint="/billing_data/"
      :headers="headers"
      :instantLoad="true"
      :key="refreshKey"
      :queryParams="{
        classroom: selectedClassroom,
        start_date: start_date,
        end_date: end_date,
      }"
    >
      <template #[`item.date`]="{ item }">
        {{ moment(item.created_at).format("DD-MMM-YY") }}
      </template>

      <template #[`item.amount`]="{ item }">
        {{ item.pay_amount }}
      </template>

      <template #[`item.receipt_copy`]="{ item }">
        <v-btn color="primary" @click="viewImage(item.receipt_copy)"
          >view Reciept</v-btn
        >
        &nbsp;
        <v-btn color="primary" @click="downloadReceipt(selectedImage)"
          >Download</v-btn
        >
      </template>
    </DataTableSSR>

    <v-dialog v-model="dialog" width="auto">
      <v-card>
        <v-card-text>
          <v-img :src="selectedImage" max-height="500" max-width="300"></v-img>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="cancel_dialogs">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
  
  <script>
import DataTableSSR from "../../components/global/DataTableSSR.vue";

export default {
  components: { DataTableSSR },

  data: () => ({
    dialogs: false,
    dialog: false,
    selectedImage: "",
    selectedClass: null,
    selectedClassroom: [],
    refreshKey: 0,
    start_date: null,
    end_date: null,
    totalAmount: null,
    rooms: "",
    loading: false,
    pay_amount: "",
    receipt_copy: null,
    headers: [
      {
        text: "Payment Date",
        value: "date",
      },
      {
        text: "Class Name",
        value: "rooms",
      },

      {
        text: "Total Amount",
        value: "total_amount",
      },

      {
        text: "Amount",
        value: "amount",
      },
      {
        text: "Receipt Copy",
        value: "receipt_copy",
        align: "center",
      },
    ],
    userData: [],
    userName: [],
    uniqueNames: [],
  }),
  mounted() {
    this.loadUser();
    this.loadUsername();
    this.uniqueNames = this.filteredNames;
  },
  computed: {
    filteredUserData() {
      if (this.rooms) {
        return this.userData.filter((item) => item.id === this.rooms);
      }
      return [];
    },
    filteredUserData() {
      return this.userData.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.name === item.name)
      );
    },
  },

  methods: {
    loadUser() {
      this.$api.get("/room_user_payment/").then((r) => {
        this.userData = r.data.results;
      });
    },
    getTotalAmount() {
      if (this.rooms) {
        const filteredUser = this.userData.find(
          (item) => item.id === this.rooms
        );
        this.totalAmount = filteredUser ? filteredUser.amount : 0;
      } else {
        this.totalAmount = 0;
      }
    },
    loadUsername() {
      this.$api.get("/rooms/room-subject-teachers/").then((r) => {
        this.userName = r.data.results;
      });
    },
    viewImage(imageUrl) {
      this.selectedImage = imageUrl;
      this.dialog = true;
    },

    cancel_dialog() {
      this.dialogs = false;
    },

    cancel_dialogs() {
      this.dialog = false;
    },
    async downloadReceipt(receiptUrl) {
      const response = await fetch(receiptUrl);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "download.png";
      link.click();
      URL.revokeObjectURL(url);
    },
    save() {
      const formData = new FormData();
      formData.append("rooms", this.rooms);
      formData.append("pay_amount", this.pay_amount);
      formData.append("receipt_copy", this.receipt_copy);
      this.$api
        .post("/billing/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.dialogs = false;
          this.loading = true;
          this.refreshKey += 1;
          this.resetValues();
        });
    },
    resetValues() {
      this.rooms = null;
      this.pay_amount = null;
      this.receipt_copy = null;
    },
  },
};
</script>
  
  <style></style>
  